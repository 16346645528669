.signup {
  padding: 4.8rem 4.8rem 2.4rem;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-flow: column;
}

.signup__container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  min-height: 41.6rem;
}

.signup__logo {
  position: absolute;
  left: 4.8rem;
  top: 4.8rem;
}

.signup__register {
  width: 40rem;
  min-width: 40rem;
  box-sizing: border-box;
  padding: 2.4rem;
}

.signup__title {
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 3rem;
  margin: 0 0 0.8rem;
}

.signup__subtitle {
  color: #888;
  margin-bottom: 4.8rem;
}

.signup__spacer {
  width: 18.4rem;
}

.signup__footer {
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
  color: #888;
}
