.task-item {
  border-radius: 8px !important;
  margin: 0 0 0.8rem 0 !important;
  text-align: left !important;
  display: block !important;
  width: 100% !important;
}

.task-item .MuiTouchRipple-root {
  color: #888;
}

.task-item__container {
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  min-height: 5.6rem;
  transition-property: background-color box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}

.task-item__checkbox {
  align-items: center;
  display: flex;
  height: 5.6rem;
  flex-grow: 0;
  justify-content: center;
  min-width: 5.6rem;
  width: 5.6rem;
}

.task-item__title {
  flex-grow: 1;
  line-height: 1.8rem;
  overflow: hidden;
  margin: 1.9rem 0 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-item__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-item__icons {
  height: 2.4rem;
  padding: 1.1rem 0 1.6rem 0;
  color: #888;
}

.task-item_snooze {
  padding: 0.4rem 0 0.4rem 0.4rem;
  color: #888;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms, visibility 200ms;
}

.task-item:hover .task-item_snooze {
  opacity: 1;
  visibility: visible;
}

.task-item__avatar {
  box-sizing: border-box;
  min-width: 5.6rem;
  overflow: hidden;
  padding: 1.6rem;
  width: 5.6rem;
}

.avatar {
  border-radius: 50%;
  height: 2.4rem;
  vertical-align: middle;
  width: 2.4rem;
}
