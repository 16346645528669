.color-box {
  border-radius: 8px;
  box-sizing: border-box;
  padding: 2.4rem;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.color-box__back {
  margin-bottom: 2.4rem;
}

.color-box__header {
  margin: 0.8rem 0.8rem 2.4rem;
}

.color-box__header h2 {
  font-size: 1.4rem;
  font-weight: normal;
  margin: 0;
}

.color-box__header span {
  color: #888;
}

.color-box__fab {
  margin-top: auto;
  display: flex;
}
