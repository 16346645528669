.icon rect {
  stroke: currentColor;
}

.icon path {
  fill: currentColor;
}

.stroke-icon path {
  stroke: currentColor;
}

.weather-icon {
  margin: 0.4rem;
}
