.dashboard {
  display: flex;
  height: 100%;
  position: relative;
}

.dashboard__master {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  height: 100%;
  min-width: 24.8rem;
  overflow-y: hidden;
  padding: 0.8rem 0 0;
  width: 24.8rem;
}

.dashboard__master-header {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 1.2rem;
  color: #888;
}

.dashboard__detail {
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
}

.dashboard__list-add {
  margin-top: auto;
  padding: 1.6rem;
}
