.list-item {
  border-radius: 4px !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  display: flex !important;
  height: 5.6rem !important;
  margin: 0 0 0.8rem 0 !important;
  text-decoration: none !important;
  transition: background-color 200ms ease-out !important;
  justify-content: flex-start !important;
}

.list-item:hover,
.list-item.active {
  outline: none;
}

.list-item .MuiTouchRipple-root {
  color: #888;
}

.list-item__icon {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 5.2rem;
  color: #888;
}

.list-item__icon svg,
.icon-cal__date {
  transition: color 200ms ease-out !important;
}

.list-item__text {
  margin: auto 0;
}

.list-item__title {
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 16.8rem;
}

.list-item__subtitle {
  color: #888;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 16.8rem;
}
