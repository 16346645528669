.list-details {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 102.4rem;
  min-height: 100%;
  padding: 2.8rem 5.6rem 0 5.6rem;
}

.list-details__header {
  display: flex;
}

.list-details__header__title {
  padding: 1.2rem 0 3.2rem 0;
}

.list-details__header__title h1 {
  display: flex;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
  max-width: 56rem;
}

.list-details__header__title p {
  letter-spacing: 0.002em;
  margin: 0.8rem 0 0 0;
  max-width: 35.6rem;
}

.list-details__header__actions {
  margin-left: auto;
  color: #888;
}

.list-details__header__actions .MuiButtonBase-root {
  margin-left: 1.6rem;
}

.list-details__header__actions .avatar {
  margin-left: 2.4rem;
  display: inline;
}

.section__title {
  line-height: 2rem;
  line-height: 1.43;
  font-size: 1.4rem;
  font-weight: normal;
  margin: 0 0 1.6rem;
}

.section__title button {
  margin: -6px -8px;
  text-transform: none;
}

.section__title svg {
  margin-left: 2px;
}

.list-details__task-add {
  bottom: 2.4rem;
  position: absolute;
  right: 2.4rem;
}
