@import-normalize;

html {
  font-size: 62.5%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'cv03' on, 'cv04' on;
  font-size: 1.4rem;
  line-height: 1.43;
  overscroll-behavior: none;
  overflow: scroll;
}

input,
button,
select,
textarea,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

hr {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  box-sizing: content-box;
  height: 0;
  margin: 0 0 1.6rem 0;
}

svg {
  display: block;
}

a {
  color: #888;
  text-decoration: none;
}

.center-box {
  display: flex;
  height: 100%;
  overflow: scroll;
  padding: 0;
  margin: 0;
}

.center-box > * {
  margin: auto;
}

.MuiMenu-paper .MuiMenuItem-root {
  padding-top: 4px;
  padding-bottom: 4px;
}

.MuiMenu-paper .MuiListItemIcon-root {
  min-width: 4rem;
}

.MuiMenu-paper .MuiListItemText-root {
  display: flex;
}

.MuiMenu-paper .MuiListItemText-root .MuiTypography-root {
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: inline;
}

.MuiMenu-paper .MuiListItemText-root .MuiTypography-colorTextSecondary {
  flex-grow: 1;
  text-align: right;
}
