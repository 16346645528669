.task-details .color-box {
  padding: 1.6rem;
}

.task-details__header {
  display: flex;
}

.task-details__header__close {
  color: #888;
}

.task-details__header__actions {
  margin-left: auto;
  color: #888;
}

.task-details__header__actions .MuiButtonBase-root {
  margin-left: 1.3rem;
}

.task-details__header__actions .MuiButtonBase-root#task-action-menu {
  margin-left: 0.3rem;
}

.task-details__header__actions .avatar {
  margin-left: 2.4rem;
  display: inline;
}

.task-contents {
  padding: 4rem;
  box-sizing: content-box;
  letter-spacing: 0.002em;
  width: 41.8rem;
  margin: 0 auto;
}

.task-contents__due {
  font-weight: 500;
  color: #888;
  margin: 0 0 0.4rem;
}

.task-contents__title {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}
