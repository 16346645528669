.icon-cal {
  width: 24px;
  height: 24px;
  position: relative;
}

.icon-cal__date {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 24px;
  font-size: 7px;
  line-height: 8px;
  font-weight: 800;
  text-align: center;
}
